exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-intermeta-tsx": () => import("./../../../src/pages/about-intermeta.tsx" /* webpackChunkName: "component---src-pages-about-intermeta-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-deus-engine-tsx": () => import("./../../../src/pages/deus-engine.tsx" /* webpackChunkName: "component---src-pages-deus-engine-tsx" */),
  "component---src-pages-documentation-tsx": () => import("./../../../src/pages/documentation.tsx" /* webpackChunkName: "component---src-pages-documentation-tsx" */),
  "component---src-pages-ethics-tsx": () => import("./../../../src/pages/ethics.tsx" /* webpackChunkName: "component---src-pages-ethics-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-investors-tsx": () => import("./../../../src/pages/investors.tsx" /* webpackChunkName: "component---src-pages-investors-tsx" */),
  "component---src-pages-leadership-tsx": () => import("./../../../src/pages/leadership.tsx" /* webpackChunkName: "component---src-pages-leadership-tsx" */),
  "component---src-pages-newsroom-tsx": () => import("./../../../src/pages/newsroom.tsx" /* webpackChunkName: "component---src-pages-newsroom-tsx" */),
  "component---src-pages-partnership-tsx": () => import("./../../../src/pages/partnership.tsx" /* webpackChunkName: "component---src-pages-partnership-tsx" */),
  "component---src-pages-teammate-cameron-tsx": () => import("./../../../src/pages/teammateCameron.tsx" /* webpackChunkName: "component---src-pages-teammate-cameron-tsx" */),
  "component---src-pages-teammate-matthew-tsx": () => import("./../../../src/pages/teammateMatthew.tsx" /* webpackChunkName: "component---src-pages-teammate-matthew-tsx" */),
  "component---src-pages-teammate-michal-tsx": () => import("./../../../src/pages/teammateMichal.tsx" /* webpackChunkName: "component---src-pages-teammate-michal-tsx" */),
  "component---src-pages-teammate-owen-tsx": () => import("./../../../src/pages/teammateOwen.tsx" /* webpackChunkName: "component---src-pages-teammate-owen-tsx" */),
  "component---src-pages-teammate-swapnil-tsx": () => import("./../../../src/pages/teammateSwapnil.tsx" /* webpackChunkName: "component---src-pages-teammate-swapnil-tsx" */),
  "component---src-pages-vision-tsx": () => import("./../../../src/pages/vision.tsx" /* webpackChunkName: "component---src-pages-vision-tsx" */),
  "component---src-templates-news-tsx": () => import("./../../../src/templates/news.tsx" /* webpackChunkName: "component---src-templates-news-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

